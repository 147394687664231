<template>
  <div class="container" style="background: #183457">
    <div class="row vh-100">
      <div class="col-lg-6" style="margin: auto">
        <div class="row">
          <div class="col logo-box">
            <img src="../assets/TNT_LOGO_GOLD_02.png" width="70%" alt="" />
          </div>
        </div>
        <div class="row social-details" style="color: #f5f5f5; margin-left: 15%">
          <p>
            <i class="fab fa-instagram fa-lg"></i> <span>@thenetworktap</span>
          </p>
          <br />
          <br />
          <p>
            <i class="fas fa-globe fa-lg"></i> <span>thenetworktap.com</span>
          </p>
          <br />
          <br />
          <p>
            <i class="far fa-envelope fa-lg"> </i>
            <span>thenetworktap@gmail.com</span>
          </p>
        </div>
      </div>
      <div class="col-lg-6 reset-form-box">

        <div class="row reset-password-main">
          <div class="text-center reset-password-title-and-desc">
            <h2>Thank you For Purchasing</h2>
            <p>
              Please enter your product code <br/> and sign up using your email and password
            </p>
          </div>
          <div v-if="response == ''">
          <form
            class="login-form"
            @submit.prevent="signup"
            style="margin: auto"
          >
            <div class="form-group">
              <!-- <label for="loginemail">Email Address</label> -->
              <input
                class="form-control form-rounded"
                type="text"
                name="signUpProductCode"
                id="signUpProductCode"
                placeholder="Product Code"
                style="width: 50%"
                v-model.trim="signUpProductCode"
                required
              />
              
              <input
                class="form-control form-rounded"
                type="email"
                name="signUpEmail"
                id="signUpEmail"
                placeholder="Email"
                style="width: 50%"
                v-model.trim="signUpEmail"
                required
              />
              <input
                class="form-control form-rounded"
                type="password"
                name="signUpPassword"
                id="signUpPassword"
                placeholder="Password"
                style="width: 50%"
                v-model.trim="signUpPassword"
                minlength="6"
                required
              />
            </div>
            <br />
            <div style="text-align: center">
              <p v-if="this.loginError != ''" style="color: red">
                {{ this.loginError }}
              </p>
              <p v-if="this.signupSuccess != ''" style="color: green">
                {{ this.signupSuccess }}
              </p>
            </div>
            <div style="text-align: center">
              <button
                type="submit"
                value="signup"
                class="btn btn-primary"
                style="width: 50%"
              >
                Sign Up
              </button>
            </div>
            <!-- <br/> -->
          </form>
          <div style="text-align: center; margin-top: 2%">
            <a href="/" style="color: blue; font-size: 80%">Sign In</a><br/>
            <a href="/resetPassword" style="color: blue; font-size: 80%">Forget Password?</a>
          </div>
        </div>
          </div>

        <!-- v-else -->

        <!-- <div
          class="row mt-5 mb-3"
          v-if="response!=''"
          style="
            margin: auto;
            outline-style: solid;
            height: auto;
            width: 70%;
            border-radius: 50px;
          "
        >
          <div
            class="row mt-3"
            style="text-align: center; margin-top: 3%; height: 100%"
          >
            <i class="fas fa-check-circle fa-3x" style="color: green"></i>
            <br />
            <p>
              A link to reset your password has been sent! If you have not
              received an email, please check your junk and spam folders.
            </p>
          </div>
          <div
            class="mb-2"
            style="display: flex; width: 100%; justify-content: center"
          >
            <a href="/"
              ><button
                value="reset"
                class="btn btn-primary"
                style="width: 100% !important; padding: 6px 71px"
              >
                Done
              </button></a
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>




</template>

<script>
// import $ from "jquery";
// import {
//   getAuth,
//   sendPasswordResetEmail
// } from "firebase/auth";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   browserSessionPersistence,
//   setPersistence,
// } from "firebase/auth";
import axios from "axios";
import router from "@/router";
import store from "../store";
import {
  getAuth,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";

export default {
  data() {
    return {
      // login info
      signUpEmail: "",
      signUpPassword:"",
      signUpProductCode:"",
      loginError: "",
      signupSuccess:"",
      response:  "",
      apiURL: "https://backend-updateuser-sobafbawhq-uc.a.run.app",

    };
  },
  methods: {
    signup() {
      if (this.signUpPassword.length < 6) {
        this.loginError = "Password must be at least 6 characters!";
        return;
    }
      axios.post(this.apiURL + "/signup",{
        code:this.signUpProductCode,
        email:this.signUpEmail,
        password:this.signUpPassword
      }).then(async ()=>{
        console.log("start")
        // const userid = response.data.message;
        this.loginError =""
        // this.signupSuccess = "Please Sign In :)"
        // console.log(userid);
        // store.commit("addUserId", userid);
        // router.replace("/editUser");
        this.login()
      }).catch((error)=>{
        console.log(error.response.data.message)
        this.loginError = error.response.data.message
        console.log(error)
      })
    },
    login() {
      const auth = getAuth();

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithEmailAndPassword(
            auth,
            this.signUpEmail,
            this.signUpPassword
          )
            .then((userCredential) => {
              const userid = userCredential.user.uid;
              // console.log(userid);
              store.commit("addUserId", userid);
              router.push("/editUser");
              // this.setToken(this.token)
            })
            .catch(() => {
              // Handle error
              this.loginError = "Username/Password Error";
            });
        })
        .catch(() => {
          this.loginError = "Error Logging In";
        });
    },
      
  },

};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
  
  font-family: "Montserrat",  sans-serif;
}
p{
  font-family: "Montserrat",  sans-serif;
  font-size: 120%;
}
span{
  padding-left:1%
}
.container{
  max-width: 100%;
}
input{
  margin: auto !important;
}

.reset-password-main {
  margin-top: 40%;
}

.reset-form-box {
  background-color: #f5f5f5;
  border-bottom-left-radius: 80px 80px;
  border-top-left-radius: 80px 80px;
}
.logo-box{
  margin-left:10%
}
@media screen and (max-width: 968px) {
  .reset-password-main {
    margin-top: 10%;
  }

  .reset-form-box {
    border-bottom-left-radius: 0;
    border-top-left-radius: 80px 80px;
    border-top-right-radius: 80px 80px;
  }
    .logo-box{
    margin-left:20%
  }
  .social-details{
    font-size: 75%;
  }
}

</style>